@import "../../../theme/variable.scss";
.page__not__found {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $black;
  height: calc(100vh - 100px - 95px);

  .page__not__found--wrapper {
    padding: 50px 20px;
    .page__not__found--content {
      text-align: center;
    }
    h1 {
      font-family: $Pangram;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
      margin-bottom: 15px;
    }
    p {
      font-family: $Pangram;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      margin-bottom: 0;
    }
  }
}
