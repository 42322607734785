@import "./variable.scss";
@import "./fonts.scss";
// Bootrsrap Overwrite CSS
@media (min-width: 1200px) {
  .container {
    max-width: $maxWidth;
  }
}
.custom__container_social {
  // width: calc(100% - 30px);
  // max-width: 1440px;
  // max-width: $maxWidth;
  margin: 0 auto;
}
.custom__container {
  width: calc(100% - 30px);
  max-width: 1440px;
  // max-width: $maxWidth;
  margin: 0 auto;
}
a {
  color: #030dcf;
}

// Bootsrap overwrite

.form-group {
  margin-bottom: 22px;
}

.form-control {
  border: 1px solid #d9dce1;
  border-radius: 0.3125rem;
  color: $black;
  font-size: $fontbase;
  min-height: calc(1.5em + 0.75rem + 0.5rem);
  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &:focus {
    box-shadow: none;
    border-color: $gray;
  }

  &.form-control-flat {
    border-radius: 0;
  }
  &.form-control-rounded {
    border-radius: 4px;
  }
}
textarea.form-control {
  padding: 0.9375rem 0.75rem;
  resize: none;
}
select,
select.custom-select {
  appearance: none;
  background: $white url(../images/select-icon.svg) no-repeat;
  background-position: calc(100% - 15px) center;
  padding-right: 30px;
}
.form-control-note {
  font-style: italic;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 16px;
  color: #979797;
  margin-top: 0.3125rem;
}
.form-control-normal-note {
  font-size: 14px;
  line-height: 17px;
  color: #1a1f36;
}
.invalid-feedback {
  display: block;
  // font-size: 14px;
  color: $red;
  &:empty {
    display: none;
  }
}
.success-feedback {
  display: block;
  font-size: 14px;
  color: $green;
  width: 100%;
  margin-top: 0.25rem;
  &:empty {
    display: none;
  }
}

.label-primary {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1a1f36;
  margin-bottom: 10px;

  .required {
    color: $red;
  }
}

.label-secondary {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 6px;

  .required {
    color: $red;
  }

  &.dark {
    color: $white;
  }
}

.btn {
  font-weight: 500;
  font-size: 0.875rem;
  padding: 0.375rem 0.5rem;
  border-radius: 0.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:hover,
  &:focus {
    box-shadow: none;
    opacity: 0.9;
  }
  &.btn-rounded {
    border-radius: 4px;
  }
  &:disabled,
  &.disabled {
    color: #fff;
    background-color: #979797;
    border-color: transparent;
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
      0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
  }
}
.btn__icon {
  margin-right: 0.5rem;
}
.btn-lg {
  font-size: 1rem;
  padding: 0.6875rem 1rem;
}

.btn__flat {
  font-family: $Pangram;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.9688rem 0.75rem;
  .btn__icon--left {
    margin-right: 5px;
  }
  .btn__icon--right {
    margin-left: 5px;
  }
}
.btn__flat--dark {
  color: $white;
  background: $black;
  border-color: $black;

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background: $black;
    border-color: black;
  }
}
.btn__flat--outline {
  color: $white;
  background: $black;
  border-color: $white;

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background: $black;
    border-color: white;
  }
}

.btn__purple {
  color: $white;
  background: $purple;
  border-color: $purple;
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px #c800ff,
    0px 1px 1px rgba(0, 0, 0, 0.12);
  &:hover,
  &:focus,
  &:active {
    color: $white;
    background: $purple;
    border-color: $purple;
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px #c800ff,
      0px 1px 1px rgba(0, 0, 0, 0.12);
  }
}

.btn__green {
  color: $white;
  background: $green-logo;
  border-color: $green-logo;
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px $green-logo,
    0px 1px 1px rgba(0, 0, 0, 0.12);
  &:hover,
  &:focus,
  &:active {
    color: $white;
    background: $green-logo;
    border-color: $green-logo;
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px $green-logo,
      0px 1px 1px rgba(0, 0, 0, 0.12);
  }
}

.btn__dark {
  color: $white;
  background: $black;
  border-color: $black;
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px $black,
    0px 1px 1px rgba(0, 0, 0, 0.12);
  &:hover,
  &:focus,
  &:active {
    color: $white;
    background: $black;
    border-color: $black;
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px $black,
      0px 1px 1px rgba(0, 0, 0, 0.12);
  }
}

.btn__purple--outline {
  color: $purple;
  background: $white;
  border-color: $white;
  box-shadow: 0px 2px 5px rgba(200, 0, 255, 0.08),
    0px 0px 0px 1px rgba(200, 0, 255, 0.16), 0px 1px 1px rgba(200, 0, 255, 0.12);

  &:hover,
  &:focus,
  &:active {
    color: $purple;
    background: $white;
    border-color: $white;
    box-shadow: 0px 2px 5px rgba(200, 0, 255, 0.08),
      0px 0px 0px 1px rgba(200, 0, 255, 0.16),
      0px 1px 1px rgba(200, 0, 255, 0.12);
  }
}
.btn__default {
  color: #3c4257;
  background: $white;
  border-color: $white;
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
    0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);

  &:hover,
  &:focus,
  &:active {
    color: #3c4257;
    background: $white;
    border-color: $white;
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
      0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
  }
}

.fullwidth {
  width: 100%;
}

.btn__link {
  color: $purple;
  text-decoration: none;
  padding: 0 0.5rem;
  &:hover,
  &:focus,
  &:focus {
    color: $purple;
  }
}
.btn__link--underline {
  color: $purple;
  text-decoration: underline;
  &:hover,
  &:focus,
  &:focus {
    color: $purple;
    text-decoration: underline;
  }
}
.btn__link--deafult {
  color: $black;
  text-decoration: none;
  &:hover,
  &:focus,
  &:focus {
    color: $black;
    text-decoration: none;
  }
}
.btn__link--danger {
  color: $red;
  text-decoration: none;
  &:hover,
  &:focus,
  &:focus {
    color: $red;
    text-decoration: none;
  }
}

.card {
  border: 0;
  box-shadow: 5px 5px 20px #e5e5e5;
  border-radius: 6px;
  margin-bottom: 50px;

  &:last-child {
    margin-bottom: 0;
  }

  &.none-shadow {
    box-shadow: none;
  }

  &.transparent {
    background-color: transparent;
  }

  .card-header {
    padding: 12px 25px;
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
    min-height: 65px;
    border-radius: 6px 6px 0 0;

    &.card-header-lg {
      padding: 12px 45px;

      @media (max-width: 767px) {
        padding: 12px 15px;
      }
    }

    .card-title {
      font-weight: $bold;
      font-size: 22px;
      line-height: 26px;
      color: $black;
      margin: 0;

      @media (max-width: 767px) {
        font-size: 18px;
      }

      &.card-title-sm {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  .card-body {
    padding: 20px 45px;

    @media (max-width: 767px) {
      padding: 20px 15px;
    }

    &.card-body-sm {
      padding: 25px;

      @media (max-width: 767px) {
        padding: 15px;
      }
    }
  }
}
// Bootsrap overwrite

// Base
.cursor {
  cursor: pointer;
}
.underline {
  text-decoration: underline !important;
}

// Common Css

// Common Css

// Loader
.anticon-spin {
  animation: loadingCircle 0.6s infinite linear;
}

.loader__full {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  z-index: 10;

  &::after {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  // position: fixed;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  &.loader__cover {
    position: absolute;
  }
}

// Loader

// Slick orverwrite
.slick-arrow {
  z-index: 1;
  &.slick-prev,
  &.slick-next {
    width: 60px;
    height: 60px;
    &::before {
      font-family: inherit;
      font-size: inherit;
      opacity: 1;
    }
  }
  &.slick-prev {
    left: 0;
    background: url("../images/slick-left.svg") no-repeat;
    background-size: cover;
  }
  &.slick-next {
    right: 0;
    background: url("../images/slick-right.svg") no-repeat;
    background-size: cover;
  }
}
// Slick orverwrite

// Custom Checkbox
.custom__checkbox {
  display: inline-block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 0;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #1a1f36;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .custom__checkbox--input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}

.custom__checkbox--check {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  border-radius: 4px;
  background-color: #fff;
  // border: 1px solid #000;
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
    0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
}

.custom__checkbox {
  .custom__checkbox--input:checked ~ .custom__checkbox--check {
    background-color: $purple;
  }
}

.custom__checkbox--check:after {
  content: "";
  position: absolute;
  display: none;
}

.custom__checkbox {
  .custom__checkbox--input:checked ~ .custom__checkbox--check:after {
    display: block;
  }

  .custom__checkbox--check:after {
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

// Custom Checkbox

// Custom Checkbox Only
.custom__checkbox--only {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  position: relative;
  padding-left: 0;
  margin-bottom: 0;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #1a1f36;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 14px;
  height: 14px;

  .custom__checkbox--only-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:disabled {
      pointer-events: none;
      opacity: 0.8;
    }
  }
}

.custom__checkbox--only-check {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #dadbde;
  // box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
  // 0px 0px 0px 1px rgba(60, 66, 87, 0.16),
  // 0px 1px 1px rgba(0, 0, 0, 0.12);
}

.custom__checkbox--only {
  .custom__checkbox--only-input:checked ~ .custom__checkbox--only-check {
    background-color: $purple;
    border: 1px solid $purple;
  }
}

.custom__checkbox--only-check:after {
  content: "";
  position: absolute;
  display: none;
}

.custom__checkbox--only {
  .custom__checkbox--only-input:checked ~ .custom__checkbox--only-check:after {
    display: block;
  }

  .custom__checkbox--only-check:after {
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

// Custom Checkbox Only

// Radio
.custom__radio {
  display: inline-block;
  position: relative;
  padding-left: 27px;
  margin-bottom: 0;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1a1f36;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .custom__radio--input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
}

.custom__radio--check {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: $white;
  border: 1px solid #dadbde;
  border-radius: 50%;
}

.custom__radio {
  .custom__radio--input:checked ~ .custom__radio--check {
    background-color: $white;
  }
}

.custom__radio--check:after {
  content: "";
  position: absolute;
  display: none;
}

.custom__radio {
  .custom__radio--input:checked ~ .custom__radio--check:after {
    display: block;
  }
  .custom__radio--input:disabled ~ .custom__radio--check:after {
    display: block;
    background: #ccc;
  }

  .custom__radio--check:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $purple;
  }
}

// Radio

// Radio Btn group
.custom__radio__group {
  position: relative;
  display: inline-block;
  .custom__radio__group--button {
    appearance: none;
    opacity: 0;
    position: absolute;
    z-index: -9999;
    &:checked + .custom__radio__group--label {
      color: $purple;
      // border: 1px solid #edc9fc;
      box-shadow: 0px 2px 5px rgba(200, 0, 255, 0.08),
        0px 0px 0px 1px rgba(200, 0, 255, 0.16),
        0px 1px 1px rgba(200, 0, 255, 0.12);
    }
  }
  .custom__radio__group--label {
    font-weight: 500;
    font-size: 0.875rem;
    padding: 0.375rem 0.5rem;
    border-radius: 0.25rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #3c4257;
    background: #fff;
    // border: 1px solid #d2d6dc;
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
      0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
    margin: 0 6px;
    transition: all 0.2s ease;
  }
}
// Radio Btn group

// Antd Css overwrite
.ant-notification {
  .cm-notification {
    .ant-notification-notice-description {
      padding-right: 20px;
    }
  }
}
// Antd Css overwrite

// Impersonate
.impersonate {
  background: #f7d9ff;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .impersonate__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .impersonate__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #c800ff;
    text-align: center;
    .impersonate__link {
      color: #c800ff;
      font-weight: 500;
      font-size: 14px;
      background: transparent;
      background-image: none;
      border: 0;
      padding: 0;
      text-decoration: underline;
    }
  }
}
// Impersonate

// Main
$header: 57px;
$impersonate: 50px;
$tabs: 38px;

.main {
  &[role="header"] {
    padding-top: 57px;
  }
  &[role="header-with-tabs"] {
    padding-top: 104px;
    &[impersonate="impersonate"] {
      padding-top: 104px + 50px;
    }
  }
  &[role="head"] {
    padding-top: 66px;
  }
  &[role="tabs"] {
    padding-top: 104px;
  }
  .main__header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
}
// Main

.head {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 66px;
  background: $white;
  border-bottom: 1px solid #d9dce1;
  &.head__fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
  .head__container {
    width: calc(100% - 30px);
    max-width: $maxWidth;
    margin: 0 auto;
    &.head__container--full {
      max-width: 100%;
    }
  }
  .head__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
  }
  .head__left {
    display: flex;
    align-items: center;
  }
  .head__close {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    .btn__close {
      &:hover {
        background: #f2f2f2;
      }
    }
  }
  .head__divider {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    width: 1px;
    height: 24px;
    background: #d9dce1;
    @media (min-width: 1024px) {
      margin: 0 20px;
    }
  }
  .head__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #1a1f36;
  }
  .head__right {
    display: inline-flex;
    align-items: center;
    .btn__head {
      white-space: nowrap;
      margin-left: 15px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

// Page header
.page__header {
  width: 100%;
  padding: 30px 0;
  // margin: 30px 0 20px;
  .page__header--container {
    width: calc(100% - 30px);
    max-width: $maxWidth;
    margin: 0 auto;
  }
  .page__header--row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    min-height: 35px;
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
  .page__header--left {
    .page__header--title {
      font-family: $SFProDisplay;
      font-weight: bold;
      font-size: 22px;
      line-height: 28px;
      color: #1a1f36;
      margin-bottom: 0;
      @media (min-width: 768px) {
        font-size: 28px;
        line-height: 33px;
      }
    }
    .page__header--subtitle {
      font-family: $SFProDisplay;
      font-size: 18px;
      line-height: 22px;
      color: #1a1f36;
      margin: 12px 0 20px;
      @media (min-width: 768px) {
        font-size: 22px;
        line-height: 26px;
      }
      a {
        color: $purple;
        text-decoration: none;
      }
    }
  }
  .page__header--right {
    margin-top: 20px;
    @media (min-width: 768px) {
      margin-top: 0;
    }
    .btn {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
      + .btn {
        margin-top: 0px;
        @media (min-width: 768px) {
          margin-top: 0;
        }
      }
    }
  }
}
// Page header

// Table
.table__responsive--custom {
}
table.table__custom {
  margin-bottom: 0;

  > :not(:first-child) {
    border-top: 0;
  }
  thead {
    border-bottom: 1px solid #e3e8ee;
    th {
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      text-transform: uppercase;
      color: #1a1f36;
      padding: 0.75rem 0.5rem;
      white-space: nowrap;
    }
  }
  tbody {
    tr {
      &:hover {
        background: #fafafa;
      }
      td {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1a1f36;
        padding: 0.625rem 0.5rem;
        vertical-align: middle;
        white-space: nowrap;
      }
    }
  }
  .table__selection--column {
    width: 20px;
  }
  .table__cell--first {
    width: 20%;
  }

  .table__cell--last {
    text-align: right;
  }
}
.table__blank {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  .table__blank--text {
    color: #697386;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }
}
// Table

// Table Pagination
.table__pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 25px 0;
  .table__pagination--results {
  }
  .table__pagination--nav {
    .table__pagination--prev,
    .table__pagination--next {
      margin-left: 8px;
    }
  }
}
// Table Pagination

// Base

// React Modal Css
.ReactModal__Body--open {
  overflow: hidden;
  // padding-right: 17px;
}

.ReactModal__Body--open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open.modal {
  background: rgba(0, 0, 0, 0.5);
}

.ReactModal__Overlay--after-open.modal {
  display: block;
}

.react-modal {
  .modal-content {
    border: 0;
    border-radius: 10px;
    &.modal-content-flat {
      border-radius: 0;
    }
  }

  .modal-dialog {
    max-width: 600px;

    @media (min-width: 576px) {
      margin: 1rem auto;
    }

    &.react-modal-dialog-xs {
      max-width: 420px;
    }

    &.react-modal-dialog-sm {
      max-width: 700px;
    }

    &.react-modal-dialog-sm-md {
      max-width: 850px;
    }

    &.react-modal-dialog-md {
      max-width: 960px;
    }

    &.react-modal-dialog-lg {
      max-width: 1024px;
    }

    &.react-modal-dialog-xl {
      max-width: 1200px;
    }
  }
}

.react-modal-header {
  padding: 20px;
  position: relative;

  .react-modal-title {
    font-family: $SFProDisplay;
    font-weight: $bold;
    font-size: 16px;
    line-height: 22px;
    color: $lightblack;
    margin-bottom: 0;
    &.react-modal-title-style-2 {
      color: #000000;
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
      font-family: $Pangram;
      margin-bottom: 10px;
    }
  }

  .react-modal-subtitle {
    font-size: 16px;
    line-height: 25px;
    color: $black;
    margin-bottom: 0;
    &.react-modal-subtitle-style-2 {
      font-family: $Pangram;
      font-weight: 400;
      font-size: 17px;
      line-height: 26px;
      color: #000000;
    }
  }

  .react-modal-close {
    position: absolute;
    top: 8px;
    right: 15px;
    z-index: 1;
  }
}

.react-modal-body {
  padding: 20px;
  background: #f7fafc !important;

  &.react-modal-body-style-2 {
    background: #fff;
  }
}
.react-modal-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}
.react-modal-body-text {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.react-modal-footer {
  padding: 20px;
  border-radius: 0 0 10px 10px;
  background: $white;
  display: flex;
  justify-content: flex-end;
}

// React Modal Css

// Genral Css

// material-Lable style
.material-textfield {
  position: relative;

  .material-textfield-label {
    font-size: 14px;
    // line-height: normal;
    color: #000000;
    position: absolute;
    pointer-events: none;
    left: 14px;
    top: 16px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    margin-bottom: 0;
  }

  .material-textfield--input {
    &:focus,
    // &:read-only,
    &:disabled,
    &:valid {
      padding-top: 20px;
    }

    // &:read-only ~ .material-textfield-label,
    &:disabled ~ .material-textfield-label,
    &:valid ~ .material-textfield-label,
    &:focus ~ .material-textfield-label {
      top: 8px;
      left: 14px;
      font-weight: $bold;
      font-size: 12px;
      // line-height: normal;
      color: $black;
    }

    &:disabled ~ .material-textfield-label {
      color: #9b9b9b;
      top: 8px;
      font-size: 14px;
      // line-height: normal;
      font-weight: 700;
    }
  }
}

// material-Lable style

// Mac Window
.mac__window {
  width: 100%;
  display: block;
  .mac__window--header {
    background: #e3f0f3;
    width: 100%;
    padding: 8px 12px;
    border-radius: 4px 4px 0 0;
    display: flex;
    align-items: center;
    .mac__window--dot {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #f9f9f9;
      border-radius: 50%;
      margin: 0 5px 0 0;
      &.dot__1 {
        background: #ff64ff;
      }
      &.dot__2 {
        background: #c800ff;
      }
      &.dot__3 {
        background: #00e1e1;
      }
    }
  }
  .mac__window--body {
    position: relative;
    background: $white;
    width: 100%;
    img {
      width: 100%;
      max-width: 100%;
    }
  }
  .mac__window--inner {
    width: 100%;
    height: calc(100vh - 253px);
    .preview__default {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
// Mac Window

// Tabs
.tabs__links {
  display: flex;
  align-items: center;
  position: relative;
  background: $white;
  border-bottom: 1px solid #e5e5e5;
  width: calc(100% - 30px);
  max-width: $maxWidth;
  margin: 0 auto;
  &.tabs__links--space {
    margin-bottom: 20px;
  }
  &.tabs__links--full {
    width: 100%;
  }
  .tabs__link {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #697386;
    background: transparent;
    background-image: none;
    text-decoration: none;
    position: relative;
    padding: 9px 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 0;
    border-bottom: 2px solid transparent;
    margin-right: 26px;
    &:hover,
    &:focus {
      outline: none;
    }
    &:last-child {
      margin-right: 0;
    }
    &.active {
      color: $purple;
      border-bottom: 2px solid $purple;
    }
  }
}

.tabs__underline {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 22px;
  .btn__tab {
    font-family: $Pangram;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #697386;
    background: transparent;
    background-image: none;
    text-decoration: none;
    position: relative;
    padding: 9px 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 0;
    border-radius: 0;
    border-bottom: 2px solid transparent;
    margin-right: 26px;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      color: $black;
      border-bottom: 2px solid $black;
    }
  }
}

// Tabs

// Dropzone
.dropzone {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  .dropzone__area {
    background: $white;
    border: 1px dashed #d9dce1;
    border-radius: 5px;
    width: 100%;
    min-height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.dropzone__grid {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  .dropzone__grid--sorter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }
  .dropzone__grid--item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    width: 50px;
    height: 50px;
    margin-right: 15px;
    margin-bottom: 15px;
    position: relative;
    &:last-child {
      margin-right: 0;
    }
  }
}
.dropzone__grid--preview {
  position: relative;
  width: 100%;
  height: 50px;
  background: #d2d6dc;
  border-radius: 4px;
  > .preview__thumb {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    display: block;
    // position: absolute;
    // top: 0;
    // left: 0;
  }
}
.dropzone__grid--remove {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  .btn__remove {
    line-height: normal;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    padding: 4px;
    border-radius: 100px;
    position: absolute;
    z-index: 1;
    background: #f04533;
    // background: #000;
    svg {
      position: absolute;
      z-index: -1;
      pointer-events: none;
    }
  }
}
// Dropzone

// Crop Area
.editor__crop {
}
.react__crop {
  width: 100%;
  height: 100%;
}
.react__crop--slider {
}
.editor__crop--action {
}
// Crop Area

// Custom Container
.custom__container_social {
  .custom__wrap {
    width: 100%;
    max-width: 415px;
    margin: 0 auto;
    padding: 20px 0;
    @media (min-width: 1024px) {
      padding: 40px 0;
    }
  }
}
.custom__container {
  .custom__wrap {
    width: 100%;
    max-width: 415px;
    margin: 0 auto;
    padding: 20px 0;
    @media (min-width: 1024px) {
      padding: 40px 0;
    }
  }
}
// Custom Container

// Login / Signup
.signup__main,
.login__main {
  .signup__left-right-main,
  .login__left-right-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - 66px);
    @media (min-width: 1024px) {
      flex-direction: row;
    }
    .left__view,
    .right__view {
      display: flex;
      align-items: center;
    }
    .left__view {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 20px;
      background: #f7fafc;
      @media (min-width: 1024px) {
        padding: 70px;
        width: 100%;
      }
    }
    .right__view {
      width: 100%;
      @media (min-width: 1024px) {
        width: 100%;
        max-width: 520px;
      }
    }
  }
  .view__wrapper {
    width: 100%;
    padding: 25px 20px;
    @media (min-width: 1024px) {
      padding: 25px 50px;
    }
  }
  .view__wrapper--cta {
  }
  .view__wrapper--footer {
    font-size: 0.875rem;
    padding: 15px 0;
    text-align: center;
  }

  .signup__head {
    margin-bottom: 25px;
    .appsumo {
      margin-bottom: 15px;
    }
    h2 {
      font-family: $SFProDisplay;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #1a1f36;
      margin-bottom: 10px;
    }
    p {
      font-family: $SFProDisplay;
      font-size: 14px;
      line-height: 17px;
      color: #1a1f36;
      margin-bottom: 0;
    }
  }
  .signup__info {
    margin-bottom: 20px;
    h4 {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #1a1f36;
      margin-bottom: 18px;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #1a1f36;
      margin-bottom: 0;
    }
  }
}

// Login / Signup

// Top Navigation
.top__navigation {
  display: flex;
  align-items: center;
  position: relative;
  background: #fafafa;
  padding: 15px 0;
  width: 100%;
  // margin-bottom: 30px;
  &.top__navigation--fixed {
    // position: fixed;
    // top: 0;
    // left: 0;
    // right: 0;
    // z-index: 1;
  }
}
.top__navigation--container {
  width: calc(100% - 30px);
  max-width: $maxWidth;
  margin: 0 auto;
}
.top__navigation--row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .top__navigation--left {
    display: flex;
    align-items: center;
    .top__navigation--logo {
      margin-right: 25px;
      a {
        color: $black;
      }
    }
  }
  .top__navigation--right {
    .top__navigation--nav {
      display: flex;
      align-items: center;
      .nav__link {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-left: 15px;
        padding: 0.3125rem;
      }
    }
  }
}
.top__navigation--dropdown {
  .profile__wrapper {
    padding: 12px;
    border-bottom: 1px solid #d9dce1;
    min-width: 200px;
    .profile__name {
      color: $black;
      font-size: 14px;
      font-weight: 600;
    }
    .profile__role {
      font-size: 12px;
    }
  }
  .top__navigation--dropdown-link,
  .top__navigation--dropdown-btn {
    text-decoration: none;
    font-size: 14px;
    color: $purple;
    font-weight: 600;
    &:hover {
      color: $purple;
    }
  }
}
// Top Navigation

// Trial Period
.trial__period {
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
  .trial__period--btn {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #c800ff;
    background: #f7d9ff;
    border: 0;
    background-image: none;
    border-radius: 100px;
    padding: 6px 15px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &.disabled,
    &:disabled {
      pointer-events: none;
      opacity: 0.5;
      user-select: none;
    }
  }
}
// Trial Period

// Billing
.billing__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #d9dce1;
  .billing__header--title {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #1a1f36;
    margin-bottom: 0;
  }
}
.payment__card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid #d9dce1;
  margin-bottom: 30px;
  .payment__card--info {
    display: flex;
    align-items: center;
  }
  .payment__card--card {
    margin-right: 12px;
  }
  .payment__card--number {
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
  .payment__card--update {
    display: flex;
    align-items: flex-end;
  }
}

.blank__state {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  .blank__state--text {
    color: #697386;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
  }
}

// Billing

// Domain Setup
.domain__setup {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 35px 0;
  .btn__domain {
    margin: 0 9px;
  }
}
// Domain Setup

.property__type--list {
  @include list-style-none;
  > li {
    padding: 10px 0;
    border-bottom: 1px solid #d9dce1;
    &:last-child {
      border-bottom: 0 solid #d9dce1;
    }
  }
}
.property__features--list,
.building__features--list {
  @include list-style-none;
  display: flex;
  flex-wrap: wrap;
  > li {
    padding: 10px 0;
    width: calc(100% / 2);
  }
}

// Editor
.editor__sidebar {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
}
.editor__sidebar--left {
  width: 100%;
  height: calc(100vh - 132px);
  overflow: auto;
  @media (max-width: 1024px) {
    display: none;
  }
  @media (min-width: 1024px) {
    max-width: 500px;
    height: calc(100vh - 66px);
  }
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a3acb9;
    // outline: 1px solid slategrey;
  }

  &.is__edit {
    @media (max-width: 1024px) {
      display: block;
    }
  }
}

.editor__sidebar--left-wrapper {
  padding: 20px 20px;
  width: 100%;
  @media (min-width: 1024px) {
    padding: 20px 40px;
  }
}
.editor__sidebar--right {
  flex: 1;
  width: 100%;
  background: #f7fafc;
  height: calc(100vh - 66px);
  @media (max-width: 1024px) {
    display: none;
  }
  @media (min-width: 1024px) {
    height: calc(100%);
  }
  &.is__preview {
    @media (max-width: 1024px) {
      display: block;
    }
  }
}
.editor__sidebar--right-wrapper {
  padding: 20px;
  width: 100%;
  height: calc(100vh - 132px);
  @media (min-width: 1024px) {
    max-width: 1340px;
    margin: 0 auto;
    padding: 50px 20px;
    height: calc(100vh - 66px);
  }
}

.editor__sidebar--footer {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-top: 1px solid #d9dce1;

  @media (min-width: 1024px) {
    display: none;
  }

  .btn {
    width: 100%;
    margin: 0 10px;
  }
}

.btn-eye-disabled {
  // pointer-events: none;
  .eye-icon {
    path {
      fill: #d8d8d8;
    }
  }
}

// Editor

// Preview Template
.preview__header {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 18px;
  .preview__header--row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
  .preview__header--left,
  .preview__header--right {
  }
  .preview__header--title {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #1a1f36;
    margin-bottom: 0;
    text-align: center;
    @media (min-width: 1024px) {
      text-align: left;
    }
  }
  .preview__header--subtitle {
    font-size: 12px;
    line-height: 14px;
    font-style: italic;
    color: #1a1f36;
    margin-top: 10px;
    margin-bottom: 0;
    text-align: center;
    @media (min-width: 1024px) {
      text-align: left;
    }
  }

  .preview__header--action {
    display: flex;
    align-items: center;
    margin-top: 20px;
    @media (min-width: 768px) {
      margin-top: 0;
    }
    .btn__desktop,
    .btn__mobile,
    .btn__fullscreen {
      margin-left: 25px;
      &:first-child {
        margin-left: 0;
      }
      &.active {
        svg {
          path {
            fill: #c800ff;
          }
        }
      }
    }
  }
}
.preview__window {
}

.editor__webisite--preview {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  transform: scale(1);
  width: 100%;
  height: calc(100% - 66px);
  .responsive__scale--wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: block;
    // margin: 0 auto;
    transform-origin: top left;
    transition: all 0.2s ease-in-out;
    transform: scale(1);
    &.is__desktop {
      width: 1340px;
      height: 857px;
    }
    &.is__mobile {
      width: 375px;
      height: calc(667px - 98px);
      @media (min-width: 768px) {
        width: 375px;
        height: 667px;
      }
    }
    .preview__window {
      width: 100%;
      height: 100%;
    }
  }
  .mac__window {
    height: 100%;
    .mac__window--body {
      height: 100%;
    }
  }
  .preview__website--iframe {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    padding: 0;
    border: 0;
    background-color: #fff;
    z-index: 1;
    overflow: auto;
  }
}
// Preview Template

// Demo Template
.demo__preview {
}
// Demo Template

.pricing__module {
  padding: 0 0 15px;
  .pricing__module--inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .pricing__module--save-money {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #c800ff;
    background: rgba(200, 0, 255, 0.1);
    border-radius: 100px;
    padding: 2px 10px;
    margin: 10px 0 15px;
    margin-left: calc(100% - 296px);
  }
  .pricing__module--text {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
  }
}

// Coupon Code
.coupon__code {
  position: relative;
  display: flex;
  align-items: center;
  .coupon__code--btn {
    margin-left: 15px;
  }
}
.powered__by__stripe {
  margin-top: 10px;
  max-width: 120px;
}
// Coupon Code

// Image Picker
.image__picker {
  background: #f7fafc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 280px;
  .image__picker--thumb {
    width: 100%;
    height: 0;
    position: relative;
    padding-top: 66.66%;
    > img {
      position: absolute;
      top: 0;
      left: 0;
      object-position: center;
      object-fit: contain;
      padding: 10px;
      width: 100%;
      height: 100%;
      max-width: 100%;
      display: block;
    }
  }
  .image__picker--controls {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #d9dce1;
    .btn {
      width: 100%;
      border: 0;
      border-radius: 0;
      border-right: 1px solid #d9dce1;
      &:last-child {
        border-right: 0 solid #d9dce1;
      }
    }
  }
}
// Image Picker

// Lead Pages Get Start Screen
.leads__starter {
  background: #f7fafc;
  padding: 34px 0;
  width: 100%;

  .page__header--container{
    width: calc(100% - 30px);
    max-width: 1440px;
    margin: 0 auto;
  }
}
.leads__starter--title {
  font-family: $SFProDisplay;
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;
  color: #1a1f36;
  margin-bottom: 30px;
}
.leads__starter--row {
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
}
.leads__starter--col {
  margin-bottom: 50px;
  &:last-child {
    margin-right: 0;
  }
  @media (min-width: 1024px) {
    margin-right: 10%;
    margin-bottom: 0;
  }
}
.leads__starter--card {
  cursor: pointer;
}

.label__budge {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  border-radius: 4px;
  padding: 2px 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  &.label__budge--primary {
    color: #ffffff;
    background: #00e1e1;
  }
}

.leads__starter--info {
  padding: 8px 0 15px;
  h4 {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #1a1f36;
    margin-bottom: 8px;
  }
  p {
    font-size: 14px;
    line-height: 17px;
    color: #1a1f36;
    width: 100%;
    // max-width: 324px;
    max-width: 364px;
    margin-bottom: 8px;
  }
  .btn__link {
    padding: 0;
  }
}
.leads__starter--thumb {
  width: 100%;
  max-width: 408px;
  // padding-top: 230px;
  position: relative;
  > img {
    width: 100%;
    height: 100%;
    // position: absolute;
    // top: 0;
    // left: 0;
    object-fit: contain;
  }
  .mac__window {
    .mac__window--header {
      padding: 6px 12px;
      .mac__window--dot {
        width: 7px;
        height: 7px;
      }
    }
  }
}

// Lead Pages Get Start Screen

// Campaign
.campaign__type--list {
  @include list-style-none;
  > li {
    padding: 10px 0;
    border-bottom: 1px solid #d9dce1;
    &:last-child {
      border-bottom: 0 solid #d9dce1;
    }
  }
}
// Campaign

// Card view
.card__property--block {
  display: inline-block;
  width: 100%;
  text-decoration: none;
}
.card__property {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  padding: 10px;
  @media (min-width: 768px) {
    padding: 20px;
  }
  .card__property--thumb {
    position: relative;
    padding-top: 110%;
    margin-bottom: 20px;
    > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .card__property--overview {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    padding: 10px 12px;
  }
  .card__property--title {
    font-family: $Pangram;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
  }
  .card__property--info {
    font-family: $Pangram;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
}
// Card view

// Successful Signup
.success__app {
  width: 100%;
  min-height: calc(100vh);
  background-color: $black;
  .success__app--header {
    width: 100%;
    padding: 35px;
    svg {
      .text {
        fill: $white;
      }
    }
  }
}
.success__container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 97px);
  .success__inner {
  }

  .success__wrap {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;

    .info__icon {
      margin-bottom: 20px;
    }

    .success__title {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
      margin-bottom: 20px;
    }

    .success__subtitle {
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 20px;
    }
  }
}
// Successful Signup

// Listing website
.listing__website--main {
  @media (min-width: 1024px) {
    padding: 0 40px;
  }
}
// Listing website

// Social Links at Left
.social__links--at-left {
  position: fixed;
  top: calc(50% + 95px);
  left: 8px;
  transform-origin: 0 0;
  transform: translateY(-50%) rotate(-90deg);
  display: none;
  @media (min-width: 1024px) {
    display: block;
  }
  .social__links {
    @include list-style-none;
    > li {
      display: inline-block;
      margin-right: 35px;
      &:last-child {
        margin-right: 0;
      }
      > a {
        font-family: $Pangram;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #000;
        text-decoration: none;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
// Social Links at Left

// Ck Editor 5
.ck__editor--customized {
  .ck {
    &.ck-toolbar {
      border: 1px solid #d9dce1;
      background: $white;
      border-radius: 4px 4px 0 0 !important;
    }
  }
  .ck-editor__editable {
    border: 1px solid #d9dce1 !important;
    border-top: 0 !important;
    border-radius: 0 0 4px 4px !important;
    min-height: 300px;
    max-height: 300px;
    &:focus {
      box-shadow: none !important;
    }
  }
}
// Ck Editor 5

.sms-icon {
  text-decoration: none;
}
.copy-link {
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
  svg {
    margin-right: 5px;
  }
}

// Phone Moduale
.react-tel-input {
  .form-control {
    height: 44px;
    width: 100%;
  }
}
// Phone Moduale

.setup-social-info {
  .setup-social-info-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 15px;
  }
  .setup-social-info-url {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    .link {
      color: $purple;
      text-decoration: underline;
      max-width: 290px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      vertical-align: text-bottom;
    }
  }
}

.sl__broker__block {
  background-color: white;
  padding: 10px 20px;
  margin-bottom: 20px;
  .broker__block--thumb {
    display: block;
    text-align: center;
    margin: 0 auto 13px;
    > img {
      display: inline-block;
      width: 146px;
      height: 146px;
      border-radius: 100px;
      object-fit: cover;
    }
  }
  .broker__block--info {
    text-align: center;
    h4 {
      font-family: $Pangram;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #000000;
      margin-bottom: 13px;
    }
    p {
      font-family: $Pangram;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: #000000;
      margin-bottom: 13px;
    }
  }
  .broker__block--contact-details {
    text-align: center;
    .broker__block--label {
      font-family: $Pangram;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 1px;
      color: #000000;
      margin-bottom: 13px;
    }
    .broker__block--link {
      font-family: $Pangram;
      color: #000000;
      font-weight: 400;
      text-decoration: underline;
    }
  }
}

.social__link--listing {
  background-color: white;
  padding: 10px 20px;
  margin-bottom: 20px;
  .social__link--listing-list {
    list-style: none;
    padding: 0;
    margin: 0;
    > li {
      text-align: center;
      > a {
        font-family: $Pangram;
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        color: #000000;
        text-decoration: none;
        word-wrap: break-word;
        padding: 15px;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 0px 0px 4px 4px;
        display: block;
      }
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
}

.work_container{
  border-radius: 0px 0px 4px 4px;
  border: 1px solid #E5E5E5;
  background: #FFF;
  padding: 0px;
  display: flex;
  justify-content: space-around;
}
.work_name_section{
  width: 100%;
  .work-name{
    color: #000;
  font-family: $Pangram;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: block;
  text-align: left;
  margin: 10px 5px 5px 10px;
  word-break: break-word;
}
&.has__image{
  width: calc(100% - 95px);
}
}

.work-price{
  color: #000;
  font-family: $Pangram;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  text-align: left;
  display: block;
  margin: 0px 5px 5px 10px;
}

.work__image__container{
  border-radius: 4px;
  width: 95px;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  .work-image{
    border-radius: 4px;
    max-width: 100%;
    max-height: 100%;
  }
}

.social__link--block {
  background-color: white;
  padding: 10px 20px;
  margin-bottom: 20px;
  .label-secondary {
    font-family: $Pangram;
  }
  .form-control {
    font-family: $Pangram;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.01em;
    color: #979797;
    min-height: 54px;
    border: 1px solid $black;
  }
}
.social__link--block-title {
  font-family: $Pangram;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
  margin-bottom: 24px;
}
.tabs__block {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  .btn {
    font-family: $Pangram;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #979797;
    background-color: #f8f8f8;
    min-height: 50px;
    width: 100%;
    border-radius: 4px 0 0 4px;
    &:last-child {
      border-radius: 0 4px 4px 0;
    }
    &.active {
      color: $white;
      background-color: $black;
    }
  }
}
.form-control-phone {
  &.form-control {
    padding-left: 80px;
  }
  ~ .flag-dropdown {
    width: 70px;
    background-color: $black;
    border: 1px solid $black;
    border-radius: 4px 0 0 4px;
  }
}

.social__links--social-link {
  margin: 20px 0;
  .social__links--list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    > li {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      &:not(:last-child) {
        margin-right: 35px;
      }
      > a {
        color: $black;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.social__link--footer {
  font-family: $Pangram;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  svg {
    margin-left: 15px;
  }
  a {
    color: #000000;
  }
}

.social-link-empty-header {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 1;
  color: #fff;
  @media (min-width: 768px) {
    left: 50px;
  }
}
.social-link-empty-main {
  width: 100%;
  height: calc(100vh);
  background-color: $black;
  display: flex;
  align-items: center;
  justify-content: center;
  .social-link-empty {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }
  .social-link-empty-wrapper {
    text-align: center;
    h1 {
      font-family: $SFProDisplay;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
      text-align: center;
      margin-bottom: 13px;
    }
    p {
      font-family: $SFProDisplay;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 22px;
    }
  }
}

.short-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  .short-link-text {
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    text-decoration: underline;
    text-transform: uppercase;
    color: #c800ff;
  }
}

.theme-text {
  color: #c800ff;
}

.pdf_name{
  color: #000;
  font-family: Pangram;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.16px;
}

.pdf_label{
  color: #000;
  font-family: Pangram;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12px;
  margin-top: -7px;
}

.pdf_image_preview {
  width: 45px;
  height: 60px;
  object-fit: cover;
  border-radius: 10px;
}

.pdf_image_preview2 {
  width:  420px;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;

  @media (max-width: 767px) {
    width:  375px;
  }
}