@import "./theme/editorStyle.scss";
@import "./theme/global.scss";
@import "./theme/variable.scss";
body {
  font-family: $SFProText, sans-serif;
  font-size: $fontbase;
  color: $black;
}
.root {
  height: $width;
}
.application-container {
  height: $width;
}
