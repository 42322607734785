@import "../../../../theme/variable.scss";

.carousel__slider {

    .carousel {
        .control-dots {
            .dot {
                background-color: #D9D9D9;
                box-shadow: none !important;
                opacity: 1;
                margin: 0 4px;
                width: 12px;
                height: 12px;

                &.selected {
                    background-color: $black;
                }
            }
        }

    }
}

.carousel__testimonial__card {
    text-align: start !important;
    width: 100%;

    &.pBottom {
        padding-bottom: 20px;
    }

    p {
        font-size: 14px;
        line-height: 1.2;
        font-family: $Pangram;
        word-wrap: break-word;

        &.testomonial__description {
            margin-bottom: 11px !important;
        }
    }
}

.see__more__btn {
    border: none;
    outline: none;
    padding: 0;
    // color: #b3afaf;
    color: #3d89c0;
    background-color: transparent;
}